import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import InputError from '~/components/UI/Inputs/InputError.vue';

extend('required', {
    ...required,
    message: 'A mező kitöltése kötelező',
});

extend('max', {
    ...max,
    params: ['length'],
    message: 'A mező értéke nem lehet hosszabb, mint {length} karakter',
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('InputError', InputError);
