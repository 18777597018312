<template>
    <div>
        <BaseModal :close="close" :is-open="isOpen">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim>
                <form novalidate class="w-full" @submit.prevent="handleSubmit(onSubmit)">
                    <Transition tag="div" name="fade-table-creation-form-panel" class="relative h-full" mode="out-in">
                        {{/* * */}}
                        {{/* * */}}
                        {{/* * */}}
                        {{/* Step 1 */}}
                        {{/* * */}}
                        <div v-if="activeStepIndex === 0" key="table-creation-form-panel-0" class="h-full">
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Név</h2>

                            <ValidationProvider v-slot="{ errors, invalid }" vid="name" tag="div" rules="required">
                                <MobileModalContentWrapper>
                                    <label class="p-label required">Táblázat neve</label>
                                    <InputText
                                        v-model="form.name"
                                        name="new_table_name"
                                        placeholder="Táblázat neve"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': errors.length }"
                                        type="text"
                                    />
                                    <InputError :errors="errors" />

                                    <label class="p-label mt-4">Leírás (opcionális)</label>
                                    <Textarea v-model="form.description" name="new_table_description" rows="5" />
                                </MobileModalContentWrapper>

                                <div class="flex justify-end mt-12">
                                    <Button
                                        type="button"
                                        label="Következő"
                                        :disabled="invalid"
                                        @click="moveToNextStep"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>

                        <div
                            v-else-if="activeStepIndex === 1"
                            key="table-creation-form-panel-1"
                            class="h-full flex flex-col"
                        >
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Piktogram</h2>

                            <ValidationProvider
                                v-slot="{ errors, invalid }"
                                vid="pictogram"
                                tag="div"
                                class="flex flex-col flex-1"
                            >
                                <MobileModalContentWrapper>
                                    <label class="p-label">Táblázat piktogramja (opcionális)</label>

                                    <input v-model="form.pictogram" type="hidden" />
                                    <br />
                                    <div class="flex flex-col items-start">
                                        <i
                                            v-if="form.pictogram"
                                            :class="`bx ${form.pictogram.value} text-2xl text-black my-3`"
                                        ></i>
                                        <div class="flex">
                                            <Button
                                                type="button"
                                                :label="!form.pictogram ? 'Válassz piktogramot' : 'Módosítás'"
                                                class="mr-4"
                                                @click="openPictogramSelectPanel"
                                            />
                                            <Button
                                                v-if="form.pictogram"
                                                type="button"
                                                label="Törlés"
                                                class="p-button-danger p-button-outlined"
                                                icon="pi pi-trash"
                                                @click="form.pictogram = null"
                                            />
                                        </div>
                                    </div>

                                    <InputError :errors="errors" />
                                </MobileModalContentWrapper>

                                <div class="flex justify-between mt-12">
                                    <Button
                                        type="button"
                                        label="Vissza"
                                        class="p-button-outlined"
                                        @click="moveToPrevStep"
                                    />
                                    <Button
                                        type="button"
                                        label="Következő"
                                        :disabled="invalid"
                                        @click="moveToNextStep"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>

                        <div v-else-if="activeStepIndex === 2" key="table-creation-form-panel-2">
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Kitöltési rend</h2>

                            <MobileModalContentWrapper>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        vid="required_fill_out_interval"
                                        tag="div"
                                        rules="required"
                                    >
                                        <label class="p-label"> Kitöltés rendszeressége </label>

                                        <Dropdown
                                            v-model="form.required_fill_out_interval"
                                            :options="$store.state.table.requiredFillOutIntervalOptions"
                                            option-label="label"
                                            placeholder="Válassz"
                                        />
                                        <InputError :errors="errors" />
                                    </ValidationProvider>

                                    <ValidationProvider
                                        v-if="form.required_fill_out_interval.value !== 'any'"
                                        v-slot="{ errors }"
                                        vid="required_fill_out_times"
                                        tag="div"
                                        rules="required"
                                    >
                                        <label class="p-label"> Kitöltés száma </label>

                                        <Dropdown
                                            v-model="form.required_fill_out_times"
                                            :options="$store.state.table.requiredFillOutTimesOptions"
                                            option-label="label"
                                            placeholder="Válassz"
                                        />
                                        <InputError :errors="errors" />
                                    </ValidationProvider>

                                    <ValidationProvider
                                        v-if="form.required_fill_out_interval.is_due_date_required"
                                        v-slot="{ errors }"
                                        vid="required_fill_out_due_date"
                                        tag="div"
                                        class="col-span-1 sm:col-span-2"
                                        rules="required"
                                    >
                                        <label class="p-label required"> Következő kitöltés esedékessége </label>

                                        <v-date-picker
                                            v-model="form.required_fill_out_due_date"
                                            mode="date"
                                            :min-date="new Date()"
                                            :masks="{
                                                input: 'YYYY-MM-DD',
                                            }"
                                            :is-expanded="true"
                                            :is24hr="true"
                                            class="w-full"
                                            :class="{ 'v-date-picker--mobile': $device.isMobileOrTablet }"
                                            @popoverWillShow="$store.dispatch('layout/handleDatepickerPopoverShow')"
                                            @popoverWillHide="$store.dispatch('layout/handleDatepickerPopoverHide')"
                                        >
                                        </v-date-picker>
                                        <InputError :errors="errors" />
                                    </ValidationProvider>
                                </div>
                            </MobileModalContentWrapper>

                            <div class="flex justify-between mt-12">
                                <Button
                                    type="button"
                                    label="Vissza"
                                    class="p-button-outlined"
                                    @click="moveToPrevStep"
                                />
                                <Button
                                    type="button"
                                    label="Következő"
                                    :disabled="
                                        form.required_fill_out_interval.is_due_date_required &&
                                        (!form.required_fill_out_due_date || !form.required_fill_out_times.value)
                                    "
                                    @click="moveToNextStep"
                                />
                            </div>
                        </div>

                        <div v-else-if="activeStepIndex === 3" key="table-creation-form-panel-3">
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Dátumozás</h2>

                            <ValidationProvider v-slot="{ errors, invalid }" vid="auto_fill_date" tag="div">
                                <MobileModalContentWrapper>
                                    <div class="flex items-start mb-5">
                                        <InputSwitch v-model="form.auto_fill_date" />
                                        <div class="inline-block ml-3">
                                            Dátumozás automatikus vezetése a táblázat első oszlopában
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <InputSwitch v-model="form.display_day_period_abbr" />
                                        <div class="inline-block ml-3">
                                            Napszak kijelzése a táblázat első oszlopában lévő dátum után
                                            (délelőtt/délután)
                                        </div>
                                    </div>

                                    <InputError :errors="errors" />
                                </MobileModalContentWrapper>

                                <div class="flex justify-between mt-12">
                                    <Button
                                        type="button"
                                        label="Vissza"
                                        class="p-button-outlined"
                                        @click="moveToPrevStep"
                                    />
                                    <Button
                                        type="button"
                                        label="Következő"
                                        :disabled="invalid"
                                        @click="moveToNextStep"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>

                        <div v-else-if="activeStepIndex === 4" key="table-creation-form-panel-4">
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Jogosultságok</h2>

                            <ValidationProvider
                                v-slot="{ errors, invalid }"
                                vid="permitted_user_roles"
                                tag="div"
                                rules="required"
                            >
                                <MobileModalContentWrapper>
                                    <label class="p-label required">Ki láthatja a táblázatot?</label>

                                    <div class="grid grid-cols-2 gap-x-5 pt-5 sm:pt-0">
                                        <div
                                            v-for="role in $store.state.auth.roles"
                                            :key="`role-${role.id}`"
                                            class="col-span-2 sm:col-span-1"
                                        >
                                            <div class="field-checkbox mb-4 sm:mb-2">
                                                <Checkbox
                                                    :id="`role-${role.id}`"
                                                    v-model="form.permitted_user_roles"
                                                    name="permission"
                                                    :value="role.id"
                                                    :disabled="role.id === 1"
                                                />
                                                <label :for="`role-${role.id}`">{{ role.label }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <InputError :errors="errors" />
                                </MobileModalContentWrapper>

                                <div class="flex justify-between mt-12">
                                    <Button
                                        type="button"
                                        label="Vissza"
                                        class="p-button-outlined"
                                        @click="moveToPrevStep"
                                    />
                                    <Button
                                        type="button"
                                        label="Következő"
                                        :disabled="invalid"
                                        @click="moveToNextStep"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>

                        <div v-else-if="activeStepIndex === 5" key="table-creation-form-panel-5">
                            <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - Felelős</h2>

                            <ValidationProvider v-slot="{ errors, invalid }" vid="responsible_user" tag="div">
                                <MobileModalContentWrapper>
                                    <label class="p-label">Ki a táblázat megbízott felelőse?</label>

                                    <Dropdown
                                        v-model="form.responsible_user"
                                        :options="$store.state.data.userList"
                                        option-label="full_name"
                                        placeholder="Válassz"
                                        show-clear
                                    >
                                        <template #value="slotProps">
                                            <UserBadge v-if="slotProps.value" :user="slotProps.value" />
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <UserBadge :user="slotProps.option" />
                                        </template>
                                    </Dropdown>

                                    <InputError :errors="errors" />
                                </MobileModalContentWrapper>

                                <div class="flex justify-between mt-12">
                                    <Button
                                        type="button"
                                        label="Vissza"
                                        class="p-button-outlined"
                                        @click="moveToPrevStep"
                                    />
                                    <Button
                                        type="button"
                                        label="Következő"
                                        :disabled="invalid"
                                        @click="moveToNextStep"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>

                        <TransitionGroup
                            v-else
                            :key="`table-creation-form-panel-5`"
                            tag="div"
                            name="fade-table-creation-form-column"
                            class="relative"
                        >
                            <div
                                v-for="(column, i) in form.record_columns"
                                v-show="activeStepIndex === 6 + i"
                                :key="`record-column-panel-${i}`"
                            >
                                <h2 class="break-word mb-5 mr-8">{{ modalTitle }} - {{ i + 1 }}. oszlop</h2>

                                <MobileModalContentWrapper>
                                    <div class="grid grid-cols-1 gap-5">
                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            vid="record_columns"
                                            tag="div"
                                            rules="required"
                                        >
                                            <label class="p-label required">Oszlop neve</label>

                                            <InputText
                                                v-model="column.name"
                                                name="column_name"
                                                placeholder="Adj egy nevet"
                                                autocomplete="off"
                                                :disabled="!column.isNew && !!$store.state.table.tableToEdit"
                                                :class="{ 'p-invalid': errors.length }"
                                                type="text"
                                            />

                                            <InputError :errors="errors" />
                                        </ValidationProvider>

                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            vid="record_columns"
                                            tag="div"
                                            rules="required"
                                        >
                                            <label class="p-label required">Tartalom típusa</label>
                                            <Dropdown
                                                v-model="column.content_type"
                                                :options="$store.state.record.contentTypes"
                                                option-label="label"
                                                :disabled="!column.isNew && !!$store.state.table.tableToEdit"
                                                placeholder="Válassz típust"
                                                @change="(e) => onContentTypeChange(e, i)"
                                            />
                                            <InputError :errors="errors" />
                                        </ValidationProvider>

                                        <ValidationProvider
                                            v-if="column.content_type?.id === 0"
                                            v-slot="{ errors }"
                                            vid="record_columns"
                                            tag="div"
                                        >
                                            <div class="flex items-center">
                                                <InputSwitch v-model="column.is_text_autocompletion_enabled" />
                                                <div class="ml-3">Használjon automatikus szövegkiegészítést</div>
                                            </div>

                                            <InputError :errors="errors" />
                                        </ValidationProvider>

                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            vid="record_columns"
                                            tag="div"
                                            rules="max:500"
                                        >
                                            <label class="p-label">Megjegyzés</label>
                                            <Textarea v-model="column.comment" :auto-resize="true" rows="5" />
                                            <InputError :errors="errors" />
                                        </ValidationProvider>

                                        <div class="flex items-center">
                                            <InputSwitch
                                                v-model="column.is_autofill_enabled"
                                                :disabled="
                                                    !!(column.content_type && [2].includes(column.content_type.id))
                                                "
                                            />
                                            <div class="ml-3">
                                                Automatikus kitöltés a legutóbb felvitt érték alapján
                                            </div>
                                        </div>

                                        <div class="flex items-center">
                                            <InputSwitch v-model="column.is_required" />
                                            <div class="ml-3">Kitöltés legyen kötelező</div>
                                        </div>
                                    </div>
                                </MobileModalContentWrapper>

                                <div class="flex justify-between mt-12 mb-5">
                                    <div class="flex">
                                        <Button
                                            type="button"
                                            label="Vissza"
                                            class="p-button-outlined mr-4"
                                            @click="moveToPrevStep"
                                        />
                                        <Button
                                            v-if="i > 0"
                                            type="button"
                                            :label="$device.isMobile ? '' : 'Törlés'"
                                            class="p-button-danger p-button-outlined"
                                            icon="pi pi-trash"
                                            @click="removeColumn(i)"
                                        />
                                    </div>
                                    <Button
                                        v-if="i === form.record_columns.length - 1"
                                        type="button"
                                        icon="pi pi-plus"
                                        :label="$device.isMobile ? 'Új' : 'Új oszlop'"
                                        :disabled="!column.name || !column.content_type"
                                        @click="addColumn"
                                    />
                                    <Button
                                        v-else
                                        type="button"
                                        label="Tovább"
                                        class="p-button-outlined"
                                        :disabled="!column.name || !column.content_type"
                                        @click="moveToNextStep"
                                    />
                                </div>
                                <div class="flex w-full justify-center">
                                    <Button
                                        type="submit"
                                        label="Befejezés"
                                        class="p-button-success w-full"
                                        :disabled="
                                            !column.name || !column.content_type || i !== form.record_columns.length - 1
                                        "
                                    />
                                </div>
                            </div>
                        </TransitionGroup>
                    </Transition>
                </form>
            </ValidationObserver>
        </BaseModal>

        <Dialog
            header=" "
            :visible.sync="isPictogramSelectPanelOpen"
            :container-style="{ width: '80vw' }"
            dismissable-mask
            :modal="true"
        >
            <div class="p-input-icon-left mb-5 flex items-center sticky top-0 bg-white" style="z-index: 1">
                <i class="pi pi-search" />
                <InputText
                    v-model="pictogramModalData.searchQuery"
                    placeholder="Search for an icon"
                    autocomplete="off"
                    type="text"
                />
            </div>
            <div>
                <SelectButton v-model="pictogramModalData.value" :options="filteredPictogramOptions" data-key="value">
                    <template #option="slotProps">
                        <i v-tooltip.top="slotProps.option.label" :class="`bx ${slotProps.option.label} text-xl`"></i>
                    </template>
                </SelectButton>
            </div>
            <template #footer>
                <Button
                    label="Bezárás"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="handlePictogramPanelCloseButtonClick"
                />
                <Button label="OK" icon="pi pi-check" @click="handlePictogramPanelSubmitButtonClick" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';

export default {
    name: 'TableModal',
    components: {
        Dialog,
        SelectButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            pictogramModalData: {
                value: null,
                searchQuery: '',
                options: [],
            },
            isPictogramSelectPanelOpen: false,
            activeStepIndex: 0,
            form: {},
        };
    },
    computed: {
        modalTitle() {
            if (this.$store.state.table.tableToEdit) {
                return this.tableUnderEdit.name + ' (szerkesztés)';
            } else {
                return 'Új táblázat';
            }
        },
        isFormValid() {
            return (
                this.form.name &&
                this.form.pictogram &&
                this.form.required_fill_out_interval &&
                this.form.required_fill_out_times &&
                this.form.permitted_user_roles &&
                this.form.record_columns &&
                Object.keys(this.form.record_columns).length
            );
        },
        filteredPictogramOptions() {
            if (!this.pictogramModalData.searchQuery.trim().length) {
                return this.pictogramModalData.options;
            }
            return this.pictogramModalData.options.filter(
                (pictogram) =>
                    this.pictogramModalData.searchQuery &&
                    pictogram.label.includes(this.pictogramModalData.searchQuery.toLowerCase().trim())
            );
        },
        tableUnderEdit() {
            return this.$store.state.table.tables.find((table) => table.id === this.$store.state.table.tableToEdit);
        },
    },
    watch: {
        isOpen(val) {
            if (val === true && this.$store.state.table.tableToEdit) {
                const _table = this.$clone(this.tableUnderEdit);
                this.form.name = _table.name;
                this.form.description = _table.description;
                this.form.responsible_user = _table.responsible_user;
                this.form.pictogram = { value: _table.pictogram, label: _table.pictogram };
                this.form.required_fill_out_interval = this.$store.state.table.requiredFillOutIntervalOptions.find(
                    (x) => x.value === _table.required_fill_out_interval
                );
                this.form.required_fill_out_times = this.$store.state.table.requiredFillOutTimesOptions.find(
                    (x) => x.value === _table.required_fill_out_times
                );
                this.form.required_fill_out_due_date = _table.required_fill_out_due_date;
                this.form.auto_fill_date = _table.auto_fill_date;
                this.form.display_day_period_abbr = _table.display_day_period_abbr;
                this.form.permitted_user_roles = _table.permitted_user_roles;
                this.form.record_columns = _table.record_columns.map((column) => {
                    column.content_type = this.$store.state.record.contentTypes.find(
                        (x) => x.id === column.content_type
                    );
                    return column;
                });
            } else {
                setTimeout(() => {
                    this.$store.commit('table/setTableToEdit', null);
                    this.resetForm();
                }, 300);
            }
        },
        'form.required_fill_out_interval'(value) {
            if (value.value === 'any') {
                this.form.required_fill_out_times = this.$store.state.table.requiredFillOutTimesOptions[0];
                this.form.required_fill_out_due_date = null;
            }
            if (value.value === 'day') {
                this.form.required_fill_out_due_date = null;
            }
        },
    },
    created() {
        this.resetForm();
    },
    mounted() {
        // eslint-disable-next-line nuxt/no-env-in-hooks
        if (process.browser) {
            // extract icon names from boxicons stylesheet and
            // populate icon selector panel with them
            const iconNames = [];
            for (const si in document.styleSheets) {
                const styleSheet = document.styleSheets[si];
                if (/boxicons/.test(styleSheet.href)) {
                    for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;
                        iconNames.push(styleSheet.rules[ri].selectorText.replaceAll(/(\.|::before)/g, ''));
                    }
                }
            }
            this.pictogramModalData.options = iconNames.map((name) => ({ value: name, label: name }));
        }
    },
    methods: {
        moveToPrevStep() {
            this.activeStepIndex--;
        },
        moveToNextStep() {
            this.activeStepIndex++;
        },
        addColumn() {
            this.form.record_columns.push({ isNew: true });
            this.moveToNextStep();
        },
        removeColumn(columnIndex) {
            this.moveToPrevStep();

            this.form.record_columns.splice(columnIndex, 1);
        },
        onSubmit() {
            if (this.activeStepIndex < 5) {
                this.moveToNextStep();
                return;
            }
            if (this.$store.state.table.tableToEdit) {
                this.updateTable();
            } else {
                this.createTable();
            }
        },
        getPayload() {
            return {
                name: this.form.name,
                description: this.form.description,
                pictogram: this.form.pictogram?.label || null,
                responsible_user: this.form.responsible_user?.id || null,
                required_fill_out_interval: this.form.required_fill_out_interval.value,
                required_fill_out_times: this.form.required_fill_out_times.value,
                required_fill_out_due_date: this.form.required_fill_out_due_date,
                auto_fill_date: this.form.auto_fill_date || null,
                display_day_period_abbr: this.form.display_day_period_abbr,
                permitted_user_roles: this.form.permitted_user_roles,
                record_columns: this.$clone(this.form.record_columns).map((column) => {
                    column.content_type = column.content_type.id;
                    return column;
                }),
            };
        },
        createTable() {
            try {
                this.$axios
                    .$post('/api/table', this.getPayload())
                    .then((res) => {
                        if (res.success === 1) {
                            this.$toast.add({
                                summary: 'Táblázat létrehozva',
                                severity: 'success',
                                life: 4000,
                            });

                            this.$store.commit('table/addTable', res.data);
                            this.close();
                            this.$router.push(`/tables/${res.data.id}`);

                            setTimeout(() => {
                                // we wait till the modal fades out
                                this.resetForm();
                            }, 300);
                        } else {
                            this.$toast.add({
                                summary: Object.values(res.errors)[0],
                                severity: 'error',
                                life: 4000,
                            });
                        }
                    })
                    .catch((err) => {
                        this.$toast.add({
                            summary: err.response.data.message,
                            severity: 'error',
                            life: 4000,
                        });
                    });
            } catch (err) {
                this.$toast.add({
                    summary: err,
                    severity: 'error',
                });
            }
        },
        updateTable() {
            try {
                this.$axios
                    .$put(`/api/table/${this.$store.state.table.tableToEdit}`, this.getPayload())
                    .then((res) => {
                        if (res.success === 1) {
                            this.$toast.add({
                                summary: 'Módosítások mentve',
                                severity: 'success',
                                life: 4000,
                            });

                            this.$store.commit('table/updateTable', res.data);
                            this.close();

                            setTimeout(() => {
                                // we wait till the modal fades out
                                this.$router.app.refresh();
                                this.$nuxt.$emit('refresh-tables-list');
                                this.resetForm();
                            }, 300);
                        } else {
                            this.$toast.add({
                                summary: Object.values(res.errors)[0],
                                severity: 'error',
                                life: 4000,
                            });
                        }
                    })
                    .catch((err) => {
                        this.$toast.add({
                            summary: err.response.data.message,
                            severity: 'error',
                            life: 4000,
                        });
                    });
            } catch (err) {
                this.$toast.add({
                    summary: err,
                    severity: 'error',
                });
            }
        },
        resetForm() {
            this.activeStepIndex = 0;
            this.form = {
                name: '',
                description: '',
                pictogram: null,
                responsible_user: null,
                auto_fill_date: true,
                display_day_period_abbr: false,
                permitted_user_roles: [1],
                record_columns: [{ isNew: true }],
                required_fill_out_interval: this.$store.state.table.requiredFillOutIntervalOptions[0],
                required_fill_out_times: this.$store.state.table.requiredFillOutTimesOptions[0],
                required_fill_out_due_date: null,
            };
        },
        onContentTypeChange(e, i) {
            // if selected type is calendar date, disable the
            // "fill based on previous value" switch because it doesn't have sense
            // todo: extract to constant
            if ([2].includes(e.value.id)) {
                this.form.record_columns[i].is_autofill_enabled = false;
            }
        },
        openPictogramSelectPanel() {
            this.pictogramModalData.value = this.form.pictogram;
            this.isPictogramSelectPanelOpen = true;
        },
        handlePictogramPanelCloseButtonClick() {
            this.pictogramModalData.searchQuery = '';
            this.isPictogramSelectPanelOpen = false;
        },
        handlePictogramPanelSubmitButtonClick() {
            this.pictogramModalData.searchQuery = '';
            this.form.pictogram = this.pictogramModalData.value;
            this.isPictogramSelectPanelOpen = false;
        },
    },
};
</script>

<style scoped></style>
