<template>
    <div>
        <Nuxt />
        <GridOverlay />
        <ClientOnly>
            <Toast position="top-right" />
        </ClientOnly>
    </div>
</template>

<script>
import Toast from 'primevue/toast';
import GridOverlay from '~/components/Layout/GridOverlay';

export default {
    name: 'Guest',
    components: {
        GridOverlay,
        Toast,
    },
    middleware: ['redirect-guest'],
};
</script>

<style scoped></style>
