<template>
    <div class="flex items-center justify-center w-full h-screen">
        <div>
            <div class="w-80 aspect-w-4 aspect-h-3 bg-grey-900 mb-4" style="max-width: calc(100vw - 30px)">
                <ClientOnly>
                    <img
                        :src="require(`~/assets/images/error-images/${Math.floor(Math.random() * 7)}.jpg`)"
                        alt=""
                        class="w-full h-full object-cover object-center"
                    />
                </ClientOnly>
            </div>
            <div class="text-3xl font-bold text-center mb-5">{{ error.statusCode }}</div>
            <div class="text-center">{{ error.statusCode === 404 ? 'Az oldal nem található' : error.message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
