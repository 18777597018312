const getInitialState = () => ({
    userList: [],
    casualWorkerList: [],
});

export const state = () => getInitialState();

export const mutations = {
    setUserList(state, payload) {
        state.userList = payload;
    },
    setCasualWorkerList(state, payload) {
        state.casualWorkerList = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
};

export const actions = {
    async fetchUserList({ commit }) {
        await this.$axios.$get('/api/user/list').then((res) => {
            if (res.success === 1) {
                commit('setUserList', res.data);
            }
        });
    },
    async fetchCasualWorkerList({ commit }) {
        await this.$axios.$get('/api/casual-worker/list').then((res) => {
            if (res.success === 1) {
                commit('setCasualWorkerList', res.data);
            }
        });
    },
};
