export default function ({ $axios, app }) {
    $axios.interceptors.request.use(async (request) => {
        request.meta = request.meta || {};
        request.meta.requestStartedAt = new Date().getTime();

        // console.log(request.url);
        if (request.url.includes('/api/')) {
            await app.$auth.refreshToken(request.url);
        }

        return request;
    });

    $axios.interceptors.response.use((response) => {
        // console.log(
        //     `finished ${response.config.url} ${response.config.method.toUpperCase()} in ${
        //         new Date().getTime() - response.config.meta.requestStartedAt
        //     } ms`
        // );

        return response;
    });

    $axios.onError((error) => {
        console.log('response error on ' + error?.config?.url);
        console.log(error?.response?.status + ' (' + error?.response?.statusText + ')');

        if (error?.response?.status === 401) {
            app.$auth.logOut();
        }

        // $toast.add({
        //     summary: {
        //         icon: require('~/assets/images/icons/settings.svg'),
        //         message: 'Your changes have been saved',
        //     },
        //     life: 4000,
        // });
    });
}
