const getInitialState = () => ({
    rememberMe: true,
    keepAlive: false,
    loggedIn: false,
    user: null,
    token: null,
    roles: null,
});

export const state = () => getInitialState();

export const mutations = {
    setLoggedInStatus(state, payload) {
        state.loggedIn = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    updateUser(state, payload) {
        Object.keys(payload).forEach((key) => {
            state.user[key] = payload[key];
        });
    },
    setToken(state, payload) {
        state.token = payload;
    },
    setRoles(state, payload) {
        state.roles = payload;
    },
    setRememberMe(state, payload) {
        state.rememberMe = payload;
    },
    setKeepAlive(state, payload) {
        state.keepAlive = payload;
    },
    reset(state) {
        const propToKeep = ['rememberMe', 'keepAlive'];

        Object.keys(state).forEach((key) => {
            if (!propToKeep.includes(key)) {
                state[key] = getInitialState()[key];
            }
        });
    },
};
