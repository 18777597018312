<template>
    <div class="flex items-center" :class="{ 'hover:underline cursor-pointer': link }" @click="clickHandler">
        <Avatar :image="user.computed_avatar_image" shape="circle" :size="size" class="mr-3" />
        <span class="whitespace-nowrap">
            {{ withoutLastName || $device.isMobile ? `${lastNameInitial}. ${user.first_name}` : user.full_name }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'UserBadge',
    props: {
        user: {
            type: Object,
            required: true,
        },
        withoutLastName: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'large',
        },
        link: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        lastNameInitial() {
            return this.user.last_name.split('')[0];
        },
    },
    methods: {
        clickHandler() {
            if (this.link) {
                if (this.user.email) {
                    this.$router.push(`/users/${this.user.id}`);
                } else {
                    this.$router.push(`/casual-workers/${this.user.id}`);
                }
            }
        },
    },
};
</script>

<style scoped></style>
