<template>
    <header class="sticky top-0 left-0 w-full bg-white py-2 px-4">
        <div class="flex justify-between relative z-0">
            <div class="inline-flex items-center lg:ml-16">
                <Button
                    v-if="$route.name === 'tables-table' && $userHasPermission('admin')"
                    icon="pi pi-cog"
                    icon-pos="left"
                    class="p-button-secondary p-button-outlined p-button-rounded mr-4 sm:hidden"
                    @click="$nuxt.$emit('toggle-table-context-menu')"
                />
                <Button
                    v-else-if="$route.name === 'users-id' && $userHasPermission('admin')"
                    icon-pos="left"
                    icon="pi pi-arrow-left"
                    class="p-button-secondary p-button-outlined p-button-rounded mr-4"
                    @click="$router.push(`/users`)"
                />
                <i class="text-xl" :class="`bx ${$store.state.layout.pageIcon}`"></i>
                <h1
                    class="text-lg lg:text-xl whitespace-nowrap overflow-hidden overflow-ellipsis ml-2"
                    :class="$userHasPermission('admin') ? 'max-w-[calc(100vw-190px)]' : 'max-w-[calc(100vw-120px)]'"
                >
                    {{ $store.state.layout.pageTitle }}
                </h1>
            </div>
            <div class="flex">
                <Button
                    icon="pi pi-align-justify"
                    class="p-button-rounded p-button-text p-button-secondary lg:hidden"
                    @click="$store.commit('layout/toggleIsSidebarOpen')"
                />

                <div id="menu-container" class="relative hidden lg:block lg:mr-6">
                    <button @click="toggleMenuDropdown">
                        <span class="flex items-center">
                            <Avatar
                                v-if="$user"
                                :image="$user.computed_avatar_image"
                                shape="circle"
                                size="xlarge"
                                class="hidden lg:block mr-4"
                            />
                            <i class="pi pi-chevron-down transition-transform"></i>
                        </span>

                        <Menu ref="menu" :model="items" :popup="true" append-to="menu-container" />
                    </button>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 left-0 w-full h-[1px] bg-grey-200"></div>

        <div class="vcalendar-popup-bg vcalendar-popup-bg--header"></div>
    </header>
</template>

<script>
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';

export default {
    name: 'Header',
    components: {
        Avatar,
        Menu,
    },
    data() {
        return {
            items: [
                {
                    label: 'Profil',
                    icon: 'bx bx-user',
                    command: () => {
                        this.$router.push(`/users/${this.$store.state.auth.user.id}`);
                    },
                },
                {
                    label: 'Kijelentkezés',
                    icon: 'bx bx-log-out',
                    command: () => {
                        this.$router.push('/logout');
                    },
                },
            ],
            isProfileMenuDropdownOpen: false,
        };
    },
    methods: {
        toggleMenuDropdown(event) {
            this.$refs.menu.toggle(event);
        },
    },
};
</script>

<style scoped>
.p-menu {
    left: unset !important;
    right: 0 !important;
    top: 65px !important;
}
</style>
