import { disableBodyScroll, enableBodyScroll } from '../static/scripts/body-scroll-lock.min';

export default ({ app, store, toast, $device, $dayjs }, inject) => {
    inject('disableScroll', disableBodyScroll);
    inject('enableScroll', enableBodyScroll);
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const targetScrollY = document.querySelector(selector).getBoundingClientRect().top + window.scrollY - offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('downloadFile', (url, fileName) => {
        fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    });
    inject('getRoles', (roleIDs) => {
        return store.state.auth.roles.filter((role) => roleIDs.includes(role.id));
    });
    inject('clone', (item) => {
        return JSON.parse(JSON.stringify(item));
    });
    inject('userHasPermission', (...permissionKeys) => {
        if (!store.state.auth.roles || !store.state.auth.user) {
            return false;
        }

        const userRoles = store.state.auth.user.roles.map((roleID) =>
            store.state.auth.roles.find((x) => x.id === roleID)
        );

        return userRoles.find((role) => permissionKeys.includes(role.key));
    });
    inject('preventContextMenu', (event) => {
        if ($device.isDesktop) {
            return;
        }

        event.preventDefault();
        event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
        event.stopImmediatePropagation();
        return false;
    });
};
