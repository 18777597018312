export const state = () => ({
    lastVisitedAminPagePath: '/company',
    lastVisitedTableId: null,
});

export const mutations = {
    setLastVisitedAminPagePath(state, payload) {
        state.lastVisitedAminPagePath = payload;
    },
    setLastVisitedTableId(state, payload) {
        state.lastVisitedTableId = payload;
    },
};
