export const state = () => ({
    host: null,
    windowWidth: 0,
});

export const mutations = {
    setHost(state, payload) {
        state.host = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
};

export const actions = {
    resetAllData(context) {
        context.commit('auth/reset');
        context.commit('table/reset');
        context.commit('data/reset');
        context.commit('layout/reset');
        context.commit('modals/confirmModal/reset');
    },
    async fetchEssentialData(context) {
        context.commit('table/setFetchState', {
            pending: true,
            error: null,
        });

        // we do these separately in order to only make one call to refresh token if needed
        await this.$axios.$get('/api/me').then((res) => {
            if (res.success === 1 && res.data.is_active) {
                this.$auth.setUser(res.data);
            }
        });

        const requests = [
            this.$axios.$get('/api/config'),
            this.$axios.$get('/api/table'),
            this.$axios.$get('/api/user/list'),
            this.$axios.$get('/api/casual-worker/list'),
        ];

        await Promise.allSettled(requests)
            .then(([configRes, tableRes, userListRes, casualWorkerListRes]) => {
                if (configRes.status === 'fulfilled' && configRes.value.success === 1) {
                    context.commit('auth/setRoles', configRes.value.data.roles);
                    context.commit('record/setContentTypes', configRes.value.data.recordContentTypes);
                    context.commit(
                        'table/setRequiredFillOutIntervalOptions',
                        configRes.value.data.requiredFillOutIntervalOptions
                    );
                    context.commit(
                        'table/setRequiredFillOutTimesOptions',
                        configRes.value.data.requiredFillOutTimesOptions
                    );
                }

                if (tableRes.status === 'fulfilled' && tableRes.value.success === 1) {
                    context.commit('table/setTables', tableRes.value.data);
                    context.commit('table/setFetchState', {
                        pending: false,
                        error: null,
                    });
                }

                if (userListRes.status === 'fulfilled' && userListRes.value.success === 1) {
                    context.commit('data/setUserList', userListRes.value.data);
                }

                if (casualWorkerListRes.status === 'fulfilled' && casualWorkerListRes.value.success === 1) {
                    context.commit('data/setCasualWorkerList', casualWorkerListRes.value.data);
                }
            })

            .catch((err) => {
                console.log(err);
            });
    },
    async nuxtServerInit({ dispatch, commit, state }, { $auth, req }) {
        try {
            commit('setHost', req.headers.host);
            if (!state.auth.keepAlive) {
                return;
            }

            await dispatch('fetchEssentialData');
        } catch (err) {
            console.log(err);
        }
    },
};
