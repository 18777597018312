const getInitialState = () => ({
    recordToEdit: null,
    contentTypes: [],
});

export const state = () => getInitialState();

export const mutations = {
    setRecordToEdit(state, payload) {
        state.recordToEdit = payload;
    },
    setContentTypes(state, payload) {
        state.contentTypes = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
};

export const actions = {};
