import Vue from 'vue';

Vue.mixin({
    computed: {
        loggedIn() {
            return this.$store.state.auth.loggedIn;
        },
        $user() {
            return this.$store.state.auth.user;
        },
    },
});
