import jwtDecode from 'jwt-decode';

export default (ctx, inject) => {
    const $auth = {
        getToken() {
            return ctx.store.state.auth.token;
        },
        setToken(token) {
            ctx.$axios.setHeader('Authorization', token ? 'Bearer ' + token : '');
            ctx.store.commit('auth/setToken', token);
        },
        setUser(user) {
            ctx.store.commit('auth/setUser', user);
            ctx.store.commit('auth/setLoggedInStatus', !!user);
        },
        logIn(user, token) {
            ctx.store.dispatch('fetchEssentialData');

            this.setUser(user);
            this.setToken(token);
            ctx.store.commit('auth/setKeepAlive', true);
        },
        async logOut() {
            ctx.store.commit('auth/setKeepAlive', false);

            await ctx.$axios.$post('/auth/logout').then(() => {
                ctx.store.dispatch('resetAllData');
            });

            ctx.redirect('/login');
        },
        async refreshToken(url) {
            let decodedAccessToken;
            if (this.getToken()) {
                decodedAccessToken = jwtDecode(this.getToken());
            }

            // refresh token 5 seconds before token is expired, just to make it sure
            // console.log('****************************************');
            if (!decodedAccessToken || Date.now() >= (decodedAccessToken.exp - 5) * 1000) {
                // console.log('refreshing token for ' + url);

                return await ctx.$axios
                    .$post('/auth/refresh_token')
                    .then((res) => {
                        this.setToken(res.accessToken);
                        // setting the same value does have a sense, it extends the cookie expiry it is stored in
                        ctx.store.commit('auth/setKeepAlive', ctx.store.state.auth.keepAlive);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            return new Promise((resolve) => {
                resolve(null);
            });
        },
    };

    const token = ctx.store.state.auth.token;
    ctx.$axios.setHeader('Authorization', token ? 'Bearer ' + token : '');

    inject('auth', $auth);
    ctx.$auth = $auth;
};
