const getInitialState = () => ({
    fetchState: {
        pending: null,
        error: null,
    },
    tables: [],
    requiredFillOutIntervalOptions: [],
    requiredFillOutTimesOptions: [],
    isTableModalOpen: false,
    tableToEdit: null,
    filterQuery: '',
});

export const state = () => getInitialState();

export const mutations = {
    setFetchState(state, payload) {
        state.fetchState = payload;
    },
    setTables(state, payload) {
        state.tables = payload;
    },
    addTable(state, payload) {
        state.tables = [...state.tables, payload];
    },
    updateTable(state, payload) {
        const index = state.tables.findIndex((table) => {
            return table.id === payload.id;
        });
        state.tables.splice(index, 1, payload);
    },
    removeTable(state, payload) {
        const index = state.tables.findIndex((table) => {
            return table.id === payload;
        });
        state.tables.splice(index, 1);
    },
    setIsTableModalOpen(state, payload) {
        state.isTableModalOpen = payload;
    },
    setTableToEdit(state, payload) {
        state.tableToEdit = payload;
    },
    setRequiredFillOutIntervalOptions(state, payload) {
        state.requiredFillOutIntervalOptions = payload;
    },
    setRequiredFillOutTimesOptions(state, payload) {
        state.requiredFillOutTimesOptions = payload;
    },
    setFilterQuery(state, payload) {
        state.filterQuery = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
};

export const actions = {
    async fetchTables({ commit }) {
        await this.$axios.$get('/api/table').then((res) => {
            if (res.success === 1) {
                commit('setTables', res.data);
            }
        });
    },
};
