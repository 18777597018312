import { disableBodyScroll, enableBodyScroll } from '../../static/scripts/body-scroll-lock.min';

const getInitialState = () => ({
    isSidebarOpen: true,
    areTransitionsEnabled: true,
    pageTitle: '',
    pageIcon: '',
    isDatepickerPopoverVisible: false,
});

export const state = () => getInitialState();

export const mutations = {
    setIsSidebarOpen(state, payload) {
        state.isSidebarOpen = payload;
    },
    setPageTitle(state, payload) {
        state.pageTitle = payload;
    },
    setPageIcon(state, payload) {
        state.pageIcon = payload;
    },
    toggleIsSidebarOpen(state) {
        state.isSidebarOpen = !state.isSidebarOpen;
    },
    setAreTransitionsEnabled(state, payload) {
        state.areTransitionsEnabled = payload;
    },
    setIsDatepickerPopoverVisible(state, payload) {
        state.isDatepickerPopoverVisible = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            const propToKeep = ['isSidebarOpen'];

            if (!propToKeep.includes(key)) {
                state[key] = getInitialState()[key];
            }
        });
    },
};

export const actions = {
    handleDatepickerPopoverShow({ commit }) {
        if (this.$device.isDesktop) {
            return;
        }
        setTimeout(() => {
            commit('setIsDatepickerPopoverVisible', true);
            if (!this.$device.isIos) {
                disableBodyScroll(document.querySelector('.vc-popover-content-wrapper'));
            }
        }, 100);
    },

    handleDatepickerPopoverHide({ commit }) {
        if (this.$device.isDesktop) {
            return;
        }
        enableBodyScroll(document.querySelector('.vc-popover-content-wrapper'));
        setTimeout(() => {
            commit('setIsDatepickerPopoverVisible', false);
        }, 100);
    },
};
