<template>
    <div
        class="fixed top-0 left-0 w-screen h-screen bg-black/70 transition-opacity"
        :class="{ 'pointer-events-none opacity-0': !isVisible }"
    ></div>
</template>

<script>
export default {
    name: 'OverlayBackground',
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped></style>
