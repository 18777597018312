import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2fe94890 = () => interopDefault(import('../pages/casual-workers/index.vue' /* webpackChunkName: "pages/casual-workers/index" */))
const _5c855073 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _644db1b6 = () => interopDefault(import('../pages/demo-components.vue' /* webpackChunkName: "pages/demo-components" */))
const _378c0b1c = () => interopDefault(import('../pages/iddqd.vue' /* webpackChunkName: "pages/iddqd" */))
const _6ad43fa4 = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _ad65e642 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6534d0f8 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _72cd7e22 = () => interopDefault(import('../pages/tables/index.vue' /* webpackChunkName: "pages/tables/index" */))
const _a0e757fe = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _d95fa210 = () => interopDefault(import('../pages/casual-workers/_id.vue' /* webpackChunkName: "pages/casual-workers/_id" */))
const _249725a2 = () => interopDefault(import('../pages/tables/_table.vue' /* webpackChunkName: "pages/tables/_table" */))
const _567162a9 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _40b52c70 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/casual-workers",
    component: _2fe94890,
    name: "casual-workers"
  }, {
    path: "/company",
    component: _5c855073,
    name: "company"
  }, {
    path: "/demo-components",
    component: _644db1b6,
    name: "demo-components"
  }, {
    path: "/iddqd",
    component: _378c0b1c,
    name: "iddqd"
  }, {
    path: "/join",
    component: _6ad43fa4,
    name: "join"
  }, {
    path: "/login",
    component: _ad65e642,
    name: "login"
  }, {
    path: "/logout",
    component: _6534d0f8,
    name: "logout"
  }, {
    path: "/tables",
    component: _72cd7e22,
    name: "tables"
  }, {
    path: "/users",
    component: _a0e757fe,
    name: "users"
  }, {
    path: "/casual-workers/:id?",
    component: _d95fa210,
    name: "casual-workers-id"
  }, {
    path: "/tables/:table",
    component: _249725a2,
    name: "tables-table"
  }, {
    path: "/users/:id",
    component: _567162a9,
    name: "users-id"
  }, {
    path: "/",
    component: _40b52c70,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
