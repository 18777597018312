<template>
    <div
        class="wrapper relative mx-auto w-[calc(100vw-2rem)] z-0"
        :class="$store.state.layout.isSidebarOpen ? 'lg:w-[calc(100vw-28rem)]' : 'lg:w-[calc(100vw-14rem)]'"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Wrapper',
};
</script>

<style>
.transitions-enabled .wrapper {
    transition: width 0.3s ease;
}
</style>
