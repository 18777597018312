import createPersistedState from 'vuex-persistedstate';

export default ({ store, $cookies }) => {
    createPersistedState({
        key: 'vuex_data',
        paths: ['dev', 'layout.isSidebarOpen', 'auth.rememberMe', 'routing'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365 * 2,
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);

    createPersistedState({
        key: 'vuex_login_token',
        paths: ['auth.token'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: 60 * 15,
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);

    createPersistedState({
        key: 'vuex_login_keepalive',
        paths: ['auth.keepAlive'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: store.state.auth.rememberMe ? 60 * 60 * 24 * 60 : 60 * 60, // 60d / 1h
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);
};
