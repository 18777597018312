<template>
    <div class="min-h-[calc(100vh-400px)] md:min-h-[unset] md:h-auto pt-1 px-5 -mx-5">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'MobileModalContentWrapper',
};
</script>

<style scoped></style>
