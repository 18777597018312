import Vue from 'vue';

import UserBadge from '../components/Generic/UserBadge';
import Wrapper from '../components/UI/Wrapper';
import Tag from '../components/UI/Tag';
import OverlayBackground from '../components/UI/OverlayBackground';
import MobileModalContentWrapper from '../components/UI/MobileModalContentWrapper';

Vue.component('Wrapper', Wrapper);
Vue.component('UserBadge', UserBadge);
Vue.component('Tag', Tag);
Vue.component('OverlayBackground', OverlayBackground);
Vue.component('MobileModalContentWrapper', MobileModalContentWrapper);
