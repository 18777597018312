import Vue from 'vue';
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    locale: 'hu-HU',
    datePicker: {
        popover: {
            placement: 'bottom-end',
            positionFixed: true,
        },
    },
});
