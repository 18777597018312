const getInitialState = () => ({
    isOpen: false,
    header: '',
    message: '',
    accept: null,
    reject: null,
    acceptLabel: '',
    rejectLabel: '',
});

export const state = () => getInitialState();

export const mutations = {
    setIsOpen(state, payload) {
        state.isOpen = payload;
    },
    setHeader(state, payload) {
        state.header = payload;
    },
    setMessage(state, payload) {
        state.message = payload;
    },
    setAccept(state, payload) {
        state.accept = payload;
    },
    setReject(state, payload) {
        state.reject = payload;
    },
    setAcceptLabel(state, payload) {
        state.acceptLabel = payload;
    },
    setRejectLabel(state, payload) {
        state.rejectLabel = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
};

export const actions = {
    open(
        { commit },
        { header = 'Megerősítés', message = 'Biztos benne?', accept, reject, acceptLabel = 'Ok', rejectLabel = 'Mégse' }
    ) {
        commit('setIsOpen', true);
        commit('setHeader', header);
        commit('setMessage', message);
        commit('setAccept', accept);
        commit('setReject', reject);
        commit('setAcceptLabel', acceptLabel);
        commit('setRejectLabel', rejectLabel);
    },
};
