<template>
    <BaseModal :close="$confirm.reject" :is-open="$store.state.modals.confirmModal.isOpen">
        <h2 class="break-word mb-5 mr-8">{{ $store.state.modals.confirmModal.header }}</h2>
        <MobileModalContentWrapper>
            <p class="whitespace-pre-line">{{ $store.state.modals.confirmModal.message }}</p>
        </MobileModalContentWrapper>
        <div class="flex justify-between mt-12">
            <Button type="button" label="Mégse" class="p-button-outlined" @click="$confirm.reject" />
            <Button type="button" label="OK" @click="$confirm.accept" />
        </div>
    </BaseModal>
</template>

<script>
export default {
    name: 'ConfirmModal',
};
</script>

<style scoped></style>
