import Vue from 'vue';

import BaseModal from '../components/Modals/BaseModal';

Vue.component('BaseModal', BaseModal);

export default (ctx, inject) => {
    const $confirm = {
        require({ header, message, accept, reject }) {
            ctx.store.commit('modals/confirmModal/reset');

            if (!accept) {
                throw new Error('Accept callback is required');
            }

            ctx.store.dispatch('modals/confirmModal/open', {
                header,
                message,
                accept,
                reject,
            });
        },
        accept() {
            ctx.store.state.modals.confirmModal.accept();
            ctx.store.commit('modals/confirmModal/setIsOpen', false);
        },
        reject() {
            ctx.store.commit('modals/confirmModal/setIsOpen', false);
            if (ctx.store.state.modals.confirmModal.reject) {
                ctx.store.state.modals.confirmModal.reject();
            }
        },
    };

    inject('confirm', $confirm);

    ctx.$confirm = $confirm;
};
