<template>
    <div class="relative">
        <div
            class="sidebar-wrapper w-3/4 sm:w-1/2 z-30 relative bg-grey-700"
            :class="
                isSidebarOpen
                    ? 'sidebar-wrapper--open translate-x-0 lg:w-72'
                    : 'sidebar-wrapper--closed translate-x-full lg:-translate-x-0 lg:w-16'
            "
            style="box-shadow: 4px 0 15px -1px rgba(0, 0, 0, 0.5)"
        >
            <NuxtLink
                to="/"
                class="absolute top-4 left-1/2 translate-x-[calc(-50%+0.5rem)] lg:translate-x-0 lg:left-4 w-28 lg:w-32 h-12 lg:h-14 bg-contain bg-left-top bg-no-repeat transition-all z-10"
                :class="{ 'opacity-0 -translate-x-4 pointer-events-none': !isSidebarOpen }"
                :style="`background-image: url(${require('~/assets/images/logos/text_logo.png')})`"
                @click.native="closeSidebarOnMobileScreens"
            ></NuxtLink>
            <div
                class="inline-block absolute top-4 right-4 lg:top-2 z-10 transition-all"
                :class="isSidebarOpen ? 'lg:-right-6' : 'lg:right-2'"
            >
                {{/* Mobile close button */}}
                <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-secondary p-button-sm lg:hidden"
                    @click="$store.commit('layout/toggleIsSidebarOpen')"
                />
                {{/* Desktop toggle button */}}
                <Button
                    :icon="`pi pi-angle-double-${isSidebarOpen ? 'left' : 'right'}`"
                    class="p-button-rounded p-button-secondary p-button-sm hidden lg:inline-flex"
                    @click="$store.commit('layout/toggleIsSidebarOpen')"
                />
            </div>

            {{/* outer overflow hidden div is needed in order to prevent box shadow peeking out */}}
            {{/* from the left side of the closed mobile menu panel on mobile */}}
            <div class="overflow-hidden lg:hidden">
                <div
                    class="flex items-center z-5 relative py-5 px-4 bg-grey-750 border-b border-grey-500"
                    style="box-shadow: 0 0 15px -2px #666970"
                >
                    <NuxtLink
                        :to="`/users/${$store.state.auth.user.id}`"
                        class="inline-flex items-center text-white"
                        @click.native="closeSidebarOnMobileScreens"
                    >
                        <Avatar :image="$user.computed_avatar_image" shape="circle" size="xlarge" />
                    </NuxtLink>
                </div>
            </div>
            <div
                class="h-28 hidden lg:block bg-grey-750 z-5 relative border-b border-grey-500"
                style="box-shadow: 0 0 15px -2px #666970"
            ></div>
            <div class="flex flex-col justify-between h-full max-h-full z-1 relative overflow-hidden">
                <div ref="scrollContainer" class="overflow-auto nicescroll">
                    <nav
                        class="overflow-hidden"
                        :class="{
                            'nav--open': $store.state.layout.isSidebarOpen,
                            'ordering-enabled': isItemChosen,
                            'ordering-disabled': !isItemChosen,
                        }"
                    >
                        <Draggable
                            v-model="localTables"
                            tag="div"
                            v-bind="dragOptions"
                            style="background-color: rgba(255, 255, 255, 0.2)"
                            @start="dragging = true"
                            @end="handleDragEnd"
                            @choose="isItemChosen = true"
                            @unchoose="isItemChosen = false"
                        >
                            <TransitionGroup tag="div" type="transition" :name="!dragging ? 'flip-list' : null">
                                <div
                                    v-for="table in localTables"
                                    :key="`table-${table.id}`"
                                    class="sidebar-nav-item"
                                    @mouseenter="onNavItemMouseEnter"
                                    @mouseleave="onNavItemMouseLeave"
                                >
                                    <button
                                        class="sidebar-nav-item__drag-handle transition-opacity"
                                        :class="{
                                            'opacity-0': !isSidebarOpen,
                                            'inline-flex': $device.isDesktop,
                                            hidden: $device.isMobileOrTablet,
                                        }"
                                    >
                                        <i
                                            class="bx bx-dots-vertical-rounded w-full h-full inline-flex items-center justify-center"
                                        ></i>
                                    </button>

                                    <NuxtLink
                                        :to="`/tables/${table.id}`"
                                        class="sidebar-nav-item__link"
                                        active-class="font-bold bg-grey-600"
                                        @click.native="closeSidebarOnMobileScreens"
                                        @contextmenu.native="$preventContextMenu"
                                    >
                                        <span class="sidebar-nav-item__link__text">
                                            {{ table.name }}
                                        </span>

                                        <i
                                            v-if="!table.required_fill_out_status.complete"
                                            class="bx ml-auto mr-2 w-5"
                                            :title="table.required_fill_out_status.message"
                                            :class="{
                                                'lg:opacity-0 lg:w-0 mr-0': !isSidebarOpen,
                                                'ml-auto mr-2': isSidebarOpen,
                                                'bx-info-circle text-white':
                                                    table.required_fill_out_status.severity === 0,
                                                'bx-error text-yellow': table.required_fill_out_status.severity === 1,
                                                'bx-error text-[#F19A36]':
                                                    table.required_fill_out_status.severity === 2,
                                                'bx-error text-red-400': table.required_fill_out_status.severity === 3,
                                            }"
                                        >
                                        </i>

                                        <i v-if="table.pictogram" :class="`bx ${table.pictogram}`"></i>
                                        <i v-else class="bx bx-table"></i>
                                    </NuxtLink>
                                </div>
                            </TransitionGroup>
                        </Draggable>
                    </nav>
                </div>

                <div class="bg-grey-750 flex flex-col gap-[1px] bg-grey-500" style="box-shadow: 0 0 15px -2px #666970">
                    <div v-if="$userHasPermission('admin')" class="sidebar-nav-item sidebar-nav-item--core">
                        <NuxtLink
                            :to="$store.state.routing.lastVisitedAminPagePath"
                            class="sidebar-nav-item__link"
                            @click.native="closeSidebarOnMobileScreens"
                        >
                            <span class="sidebar-nav-item__link__text"> Adminisztráció </span>

                            <i class="bx bx-briefcase"></i>
                        </NuxtLink>
                    </div>
                    <div class="sidebar-nav-item sidebar-nav-item--core lg:hidden">
                        <NuxtLink
                            to="/logout"
                            class="sidebar-nav-item__link"
                            @click.native="closeSidebarOnMobileScreens"
                        >
                            <span class="sidebar-nav-item__link__text"> Kijelentkezés </span>

                            <i class="bx bx-log-out"></i>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="fixed top-0 left-0 w-screen h-screen bg-grey-900/60 transition-opacity lg:hidden"
            :class="{ 'opacity-0 pointer-events-none': !isSidebarOpen }"
            @click="$store.commit('layout/toggleIsSidebarOpen')"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            dragging: false,
            isItemChosen: false,
            localTables: [],
        };
    },
    computed: {
        isSidebarOpen() {
            return this.$store.state.layout.isSidebarOpen;
        },
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost',
                handle: this.$device.isDesktop ? '.sidebar-nav-item__drag-handle' : null,
                delay: this.$device.isDesktop ? 0 : 500,
                delayOnTouchOnly: true,
            };
        },
    },
    watch: {
        '$store.state.table.tables'() {
            if (!this.$store.state.auth.user) {
                return;
            }
            this.setLocalTables();
        },
    },
    created() {
        if (this.$store.state.table.tables.length) {
            this.setLocalTables();
        }
    },
    methods: {
        onNavItemMouseEnter(e) {
            e.target.classList.add('sidebar-nav-item--hovered');
        },
        onNavItemMouseLeave(e) {
            e.target.classList.remove('sidebar-nav-item--hovered');
        },
        async handleDragEnd() {
            await this.$axios
                .$put('/api/me', {
                    preferred_table_order: this.localTables.map((x) => x.id),
                })
                .then((res) => {
                    if (res.success === 1) {
                        this.$store.commit('auth/updateUser', res.data);

                        this.$toast.add({
                            summary: 'Változások mentve',
                            severity: 'success',
                            life: 4000,
                        });
                    }
                });
            this.dragging = false;
        },
        closeSidebarOnMobileScreens() {
            if (window.innerWidth < 992) {
                this.$store.commit('layout/setIsSidebarOpen', false);
            }
        },
        async setLocalTables() {
            const _preferredTableOrder = this.$store.state.auth.user.preferred_table_order;
            const tables = this.$store.state.table.tables;

            if (!_preferredTableOrder) {
                this.localTables = tables;
            } else {
                const tablesWithSavedOrder = _preferredTableOrder.reduce((acc, curr) => {
                    const table = tables.find((t) => t.id === curr);
                    if (table) {
                        acc.push(table);
                    }
                    return acc;
                }, []);

                if (_preferredTableOrder.some((tableID) => !tables.find((table) => table.id === tableID))) {
                    const payload = _preferredTableOrder.filter((tableID) =>
                        tables.find((table) => table.id === tableID)
                    );

                    await this.$axios
                        .$put('/api/me', {
                            preferred_table_order: payload,
                        })
                        .then((res) => {
                            if (res.success === 1 && process.client) {
                                this.$store.commit('auth/updateUser', res.data);
                            }
                        });
                }

                const tablesWithoutSavedOrder = tables.filter((table) => {
                    return !_preferredTableOrder.includes(table.id);
                });

                this.localTables = [...tablesWithSavedOrder, ...tablesWithoutSavedOrder].filter((t) => !t.is_archived);
            }
        },
    },
};
</script>

<style>
.sidebar-nav-item__link.nuxt-link-exact-active {
    @apply font-bold;
}
.sidebar-nav-item:not(.sidebar-nav-item--core) .sidebar-nav-item__link.nuxt-link-exact-active {
    @apply bg-grey-750;
}

.sidebar-wrapper {
    @apply fixed lg:sticky top-0 right-0 lg:right-[unset] flex flex-col justify-between;
    height: calc(var(--vh, 1vh) * 100);
    &--closed {
        .p-button-label {
            @apply lg:opacity-0;
        }
        .sidebar-nav-item__drag-handle {
            pointer-events: none;
        }
        .sidebar-nav-item__link {
            @apply lg:pr-[1.3rem] lg:pl-[1.2rem];
            .sidebar-nav-item__link__text {
                @apply lg:opacity-0 lg:pr-0;
            }
        }
    }
}

.transitions-enabled .sidebar-wrapper {
    transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar-nav-item {
    @apply relative text-white bg-grey-700;
    transition: color 0.2s ease, background-color 0.2s ease, margin-bottom 0.2s ease, margin-top 0.2s ease;
}

.sidebar-nav-item.sidebar-nav-item--core {
    @apply bg-grey-750;
    &:hover {
        @apply bg-grey-650;
    }
}

.ordering-disabled .sidebar-nav-item--hovered {
    @apply bg-grey-725;
}

.sidebar-nav-item__link {
    @apply relative z-0 flex items-center justify-between w-full relative whitespace-nowrap h-14 pl-6 pr-4;
    transition: padding 0.2s ease;
    .sidebar-nav-item__link__text {
        @apply whitespace-nowrap overflow-hidden overflow-ellipsis transition-all pr-2;
    }
    i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        transition: all 0.2s ease;
    }
}

body.mobile-tablet .sidebar-nav-item__link {
    @apply pl-4;
}

.sidebar-nav-item__drag-handle {
    cursor: grab;
    @apply absolute top-1/2 left-0 h-full w-6 items-center justify-center -translate-y-1/2 z-10;
    &:active {
        cursor: grabbing;
    }
}

.nav--open .nav-item i {
    font-size: 1rem;
}

nav:not(.nav--open) .p-button-label {
    @apply translate-x-4;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
}

.ordering-enabled .sortable-chosen,
.ordering-enabled .sidebar-nav-item[draggable='true'] {
    margin-top: 6px;
    margin-bottom: 6px;
}
</style>
