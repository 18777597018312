import Vue from 'vue';
import 'primeicons/primeicons.css';
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import ToastService from 'primevue/toastservice';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import Avatar from 'primevue/avatar';

Vue.use(PrimeVue, {
    locale: {
        firstDayOfWeek: 1,
        dayNames: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
        dayNamesShort: ['Vas', 'Hé', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'],
        dayNamesMin: ['Vas', 'Hé', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'],
        monthNames: [
            'Január',
            'Február',
            'Március',
            'Április',
            'Május',
            'Június',
            'Július',
            'Augusztus',
            'Szeptember',
            'Október',
            'November',
            'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'],
        today: 'Ma',
        clear: 'Törlés',
        dateFormat: 'nn/hh/éééé',
        weekHeader: 'Hét',
    },
});

Vue.use(ToastService);

Vue.directive('tooltip', Tooltip);

Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('InputMask', InputMask);
Vue.component('Password', Password);
Vue.component('Checkbox', Checkbox);
Vue.component('Textarea', Textarea);
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Calendar', Calendar);
Vue.component('Button', Button);
Vue.component('Column', Column);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Avatar', Avatar);
