<template>
    <div v-if="!$store.state.auth.user">loading...</div>
    <div
        v-else
        id="__layout__content"
        :class="{
            'transitions-enabled': $store.state.layout.areTransitionsEnabled,
            'datepicker-popover-open': $store.state.layout.isDatepickerPopoverVisible,
        }"
    >
        <div class="relative flex min-h-screen">
            <Sidebar class="z-30" />
            <div class="relative flex-grow z-20">
                <Header class="z-10" />
                <Nuxt class="relative z-0" />
            </div>
        </div>

        <GridOverlay />

        <ClientOnly>
            <Toast position="top-right" />
            <ConfirmModal />
            <TableModal :is-open="$store.state.table.isTableModalOpen" :close="closeCreateTableModal" />
        </ClientOnly>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import Toast from 'primevue/toast';
import Sidebar from '../components/Layout/Sidebar';
import TableModal from '../components/Modals/TableModal';
import ConfirmModal from '~/components/Modals/ConfirmModal';
import GridOverlay from '~/components/Layout/GridOverlay';
import Header from '~/components/Layout/Header';

export default {
    name: 'Default',
    components: {
        Toast,
        GridOverlay,
        Header,
        Sidebar,
        TableModal,
        ConfirmModal,
    },
    middleware: ['redirect-user'],
    mounted() {
        window.addEventListener('resize', this.lockWrapperWidthTransition);
        window.addEventListener('resize', this.unlockWrapperWidthTransition);
        //
        // const memberIds = ['63b1e7a611287d4063d0567a', '63b1ebd04cfe04f218c99924', '63b0346a660c2dea1c3384ba'];
        // const pool = [
        //     'kenyér',
        //     'csirke',
        //     'uborka',
        //     'retek',
        //     'hagymás palacsinta',
        //     'tepertős tiramisu',
        //     'kukorica',
        //     'kecske',
        //     'hajaj',
        //     'sejhaj',
        // ];
        //
        // let counter = 0;
        //
        // const rHour = () => {
        //     return String(Math.ceil(Math.random() * 23)).padStart(2, '0');
        // };
        //
        // const rDay = () => {
        //     return String(Math.ceil(Math.random() * 28)).padStart(2, '0');
        // };
        //
        // const rMonth = () => {
        //     return String(Math.ceil(Math.random() * 12)).padStart(2, '0');
        // };
        //
        // if (this.$store.state.auth.user.role === 1) {
        //     setInterval(() => {
        //         this.$axios
        //             .$post('/api/record', {
        //                 created_at_mutable: `2022-${rMonth()}-${rDay()}T${rHour()}:39:24.855Z`,
        //                 table_id: '63b1e714acafcce79bf2e962',
        //                 data: {
        //                     szoveges_mezo: pool[Math.floor(Math.random() * pool.length)],
        //                     szoveges_mezo_auto: pool[Math.floor(Math.random() * pool.length)],
        //                     datum: `2023-${rMonth()}-${rDay()}T${rHour()}:39:24.855Z`,
        //                     oraperc_v: `2023-01-01T${rHour()}:33:24.855Z`,
        //                     csapattag: memberIds[Math.floor(Math.random() * 3)],
        //                     hofok: Math.floor(Math.random() * 10 + 1),
        //                 },
        //             })
        //             .then((res) => {
        //                 if (res.success === 1) {
        //                     counter++;
        //                     console.log('ok ' + counter);
        //                 }
        //             });
        //     }, 500);
        // }
    },
    methods: {
        closeCreateTableModal() {
            this.$store.commit('table/setIsTableModalOpen', false);
        },
        lockWrapperWidthTransition: debounce(500, true, function () {
            this.$store.commit('layout/setAreTransitionsEnabled', false);
        }),
        unlockWrapperWidthTransition: debounce(500, function () {
            this.$store.commit('layout/setAreTransitionsEnabled', true);
        }),
    },
};
</script>
